import React from "react";
import logo from "../Assets/source File-1 (1).png";
import { Box, Typography } from "@mui/material";

const FooterAbout = () => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: { xs: "center", md: "flex-start" },
        justifyContent: "flex-start",
        my: "0.5rem",
        width: { xs: "100%", sm: "80%", md: "18rem" },
        mx: { xs: "auto", md: "0" },
        px: { xs: 2, sm: 0 }, // add padding on smaller screens
        "@media (max-width: 790px)": {
          gap: "1rem",
        },
      }}
    >
      <img
        src={logo}
        alt="Untangleit"
        style={{
          width: "100%",
          maxWidth: "250px",
          height: "auto",
          marginTop: "20px",
        }}
      />
      <Typography
        sx={{
          textAlign: { xs: "center", md: "left" },
          fontFamily: "inherit",
          color: "inherit",
          fontSize: { xs: "1rem", sm: "1.1rem", md: "1.25rem" },
          mt: { xs: "1rem", md: "1.5rem" },
        }}
      >
        Your business is unique; <br />
        Your CRM should be too. <br />
        We create custom Salesforce solutions that boost productivity,
        streamline workflows, and help to grow.
      </Typography>
    </Box>
  );
};

export default FooterAbout;
