import React, { useLayoutEffect } from "react";
import { Box, Container } from "@mui/material";
import HeroSection from "./Hero";
import MissionSection from "./Mission";
import WhoWeAreSection from "./WhoWeAre";
import ValuesSection from "./Value";
import ApproachSection from "./Approach";
import JoinUsSection from "./JoinUs";
import LogIn from "../Navbar/LogIn";
import WelcomeSection from "./Welcome";

const AboutUsPage = () => {
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <HeroSection />
      <Box
        sx={{
          px: { xs: 2, sm: 5, md: 10 },
          py: { xs: 4, sm: 6, md: 10 },
          backgroundColor: "white", // Adjust background color as needed
        }}
      >
        <WelcomeSection />
        <MissionSection />
        <WhoWeAreSection />
        <ValuesSection />
        <ApproachSection />
        <JoinUsSection />
        <Container>
          <LogIn />
        </Container>
      </Box>
    </>
  );
};

export default AboutUsPage;
