import React from "react";
import { Link } from "react-router-dom";

const AboutUs = () => {
  return (
    <>
      <Link to="/about-us" data-ps2id-api="true">
        About Us
      </Link>
    </>
  );
};

export default AboutUs;
