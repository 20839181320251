import React from "react";
import { Typography, Box } from "@mui/material";
import heroimg from "../Assets/Screenshot_2024-11-04_130157_upscaled (1).webp";

const HeroSection = () => {
  return (
    <section
      style={{
        position: "relative",
        overflow: "hidden",
        height: "60vh", // Adjust this height based on the design
      }}
    >
      <img
        src={heroimg}
        alt="Hero Background"
        loading="lazy"
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          objectFit: "cover",
          zIndex: -1,
          filter: "brightness(90%) grayscale(50%)",
        }}
      />
      <Box
        sx={{
          position: "relative",
          zIndex: 1,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          height: "100%",
          color: "#fff",
          textAlign: "center",
        }}
      >
        <Typography
          sx={{
            fontWeight: "bold",
            fontSize: { xs: "2.5rem", sm: "3rem", md: "4rem" },
            fontFamily: "inherit",
          }}
        >
          About Untangleit
        </Typography>
      </Box>
    </section>
  );
};

export default HeroSection;
