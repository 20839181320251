import React from "react";
import { Typography, Container } from "@mui/material";

const JoinUsSection = () => (
  <Container
    maxWidth="lg"
    sx={{  textAlign: { xs: "left", sm: "left" }, py: 5 }}
  >
    <Typography
      variant="h4"
      sx={{
        color: "#0f1a51",
        fontWeight: "bold",
        fontSize: { xs: "1.5rem", sm: "2rem", md: "2rem" },
        fontFamily: "inherit",
      }}
      gutterBottom
    >
      Join Us
    </Typography>
    <Typography
      variant="h5"
      sx={{
        fontSize: { xs: "1.2rem", sm: "1.25rem" },
        mt: { xs: "1.2rem", sm: "1.5rem" },
        fontFamily: "inherit",
      }}
    >
      Untangleit simplifies your IT, whether you're moving to the cloud,
      optimizing your current setup, or need reliable support. We handle cloud
      migrations, boost system efficiency, and more.
    </Typography>
  </Container>
);

export default JoinUsSection;
