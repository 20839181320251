import React from "react";
import { Typography, Box, Container } from "@mui/material";

const SolutionSection = ({ solution }) => (
  <Container maxWidth="md" sx={{ py: { xs: 2, md: 4 } }}>
    <Box>
      <Typography
        variant="h6"
        sx={{
          fontSize: { xs: "0.9rem", sm: "1.05rem", md: "1.25rem" },
          color: "inherit",
          fontFamily: "inherit",
          mb: 1,
        }}
      >
        <strong style={{ fontSize: "1.5rem", color: "#0f1a51" }}>
          Solution:
        </strong>{" "}
        <br />
        {solution.description}
      </Typography>
      

      <Box
        component="ul"
        sx={{
          textAlign: { xs: "left", sm: "left" },
          listStyleType: "disc",
          paddingLeft: { xs: 2, sm: 4 },
          color: "inherit",
        }}
      >
        {solution.title.map((title, idx) => (
          <Typography
            key={idx}
            component="li"
            sx={{
              fontSize: { xs: "0.9rem", sm: "1.05rem", md: "1.25rem" },
              color: "inherit",
              my: 2.5,
              fontFamily: "inherit",
            }}
          >
            <strong style={{ color: "black" }}>{title}:</strong> {solution.details[idx]}
          </Typography>
        ))}
      </Box>
    </Box>
  </Container>
);

export default SolutionSection;
