import React from "react";
import { Typography, Box, Container } from "@mui/material";

const TechnologyUsedSection = ({ technologyUsed }) => (
  <Container maxWidth="md" sx={{ py: { xs: 2, md: 4 } }}>
    <Box>
      <Typography
        variant="h6"
        sx={{
          fontSize: "1.5rem",
          fontWeight: "bold",
          mb: 2,
          color: "#0f1a51",
          fontFamily: "inherit",
        }}
      >
        Technology Used:
      </Typography>

      <Box
        component="ul"
        sx={{
          textAlign: { xs: "left", sm: "left" },
          listStyleType: "disc",
          paddingLeft: { xs: 2, sm: 4 },
          color: "inherit",
        }}
      >
        {technologyUsed.map((tech, idx) => (
          <Typography
            key={idx}
            component="li"
            sx={{
              fontSize: { xs: "0.9rem", sm: "1.05rem", md: "1.25rem" },
              color: "inherit",
              my: 2.5,
              fontFamily: "inherit",
            }}
          >
            {tech}
          </Typography>
        ))}
      </Box>
    </Box>
  </Container>
);

export default TechnologyUsedSection;
