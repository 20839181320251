import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Pagination } from "swiper/modules";
import "swiper/css";
import "swiper/css/pagination";
import serv1 from "../Assets/implement.png";
import serv2 from "../Assets/web-maintenance.png";
import serv3 from "../Assets/services.png";
import serv4 from "../Assets/data-migration.png";
import serv5 from "../Assets/erp.png";
import serv6 from "../Assets/presentation.png";
import serv7 from "../Assets/dashboard.png";
import serv8 from "../Assets/flooring.png";
import serv9 from "../Assets/consulting.png";

const Section3 = () => {
  const solutions = [
    {
      icon: serv1,
      tiltel: "Implementation and Configuration",
      content:
        "Launch Salesforce with confidence. Expert setup and configuration ensure your CRM aligns perfectly with your unique business needs and goals.",
    },
    {
      icon: serv2,
      tiltel: "Customization and Development",
      content:
        "Transform Salesforce to fit your business. Build custom objects, fields, workflows, and automations to streamline your processes and maximize efficiency.",
    },

    {
      icon: serv3,
      tiltel: "Ongoing Support and Maintenance",
      content:
        "Maximize your Salesforce ROI with proactive support. Troubleshooting, enhancements, and updates keep your CRM running smoothly and efficiently.",
    },
    {
      icon: serv4,
      tiltel: "Data Migration and Integration",
      content:
        "Migrate to Salesforce seamlessly. Transfer and consolidate data from any existing system, ensuring a smooth and efficient transition.",
    },
    {
      icon: serv5,
      tiltel: "Extending Salesforce with Integrations",
      content:
        "Unify your business with connected systems. Integrate Salesforce with ERP, marketing automation, and customer service platforms for a streamlined workflow.",
    },
    {
      icon: serv6,
      tiltel: "Salesforce User Training and Adoption",
      content:
        "Empower your team to master Salesforce. Comprehensive training programs and resources drive user adoption and maximize your CRM investment.",
    },
    {
      icon: serv7,
      tiltel: "Salesforce Reports and Dashboards",
      content:
        "Unlock valuable insights with data visualization. Design and implement reports and dashboards to track sales performance, customer engagement, and key metrics.",
    },
    {
      icon: serv8,
      tiltel: "Salesforce Upgrades and Enhancements",
      content:
        "Stay ahead of the curve with the latest Salesforce features. Upgrade your Salesforce platform, explore new features, and implement enhancements to optimize performance.",
    },
    {
      icon: serv9,
      tiltel: "Best Practices and Consulting",
      content:
        "Achieve your business goals with expert Salesforce guidance. Leverage industry best practices and strategic advice to maximize your CRM success.",
    },
  ];

  return (
    <section
      className="elementor-section elementor-top-section elementor-element elementor-element-43c04fb add-margin elementor-section-boxed "
      data-id="43c04fb"
      data-element_type="section"
      data-settings='{"background_background":"gradient"}'
    >
      {/* <div className="elementor-background-overlay"></div> */}
      <div className="elementor-container elementor-column-gap-default">
        <div
          className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-eefbc5d ot-flex-column-vertical"
          data-id="eefbc5d"
          data-element_type="column"
        >
          <div className="elementor-widget-wrap elementor-element-populated">
            <section
              className="elementor-section elementor-inner-section elementor-element elementor-element-3b67964 elementor-section-boxed elementor-section-height-default elementor-section-height-default"
              data-id="3b67964"
              data-element_type="section"
              style={{ marginTop: "35px" }}
            >
              <div className="elementor-container elementor-column-gap-default">
                <div
                  className="elementor-column elementor-col-100 elementor-inner-column elementor-element elementor-element-158c27d ot-flex-column-vertical"
                  data-id="158c27d"
                  data-element_type="column"
                >
                  <div className="elementor-widget-wrap elementor-element-populated">
                    <div
                      className="elementor-element elementor-element-b0d750c elementor-widget elementor-widget-heading"
                      data-id="b0d750c"
                      data-element_type="widget"
                      data-widget_type="heading.default"
                    >
                      <div className="elementor-widget-container">
                        <h2
                          className="elementor-heading-title elementor-size-default"
                          style={{ fontSize: "30px" }}
                        >
                          <span style={{ color: "#F37612" }}>OUR</span> SERVICES
                        </h2>
                      </div>
                    </div>
                    <div
                      className="elementor-element elementor-element-e8bb90c elementor-widget elementor-widget-text-editor"
                      data-id="e8bb90c"
                      data-element_type="widget"
                      data-widget_type="text-editor.default"
                    >
                      <div className="elementor-widget-container">
                        <h2 style={{ fontSize: "25px", textAlign: "center" }}>
                          Transforming Businesses with CRM:{" "}
                          <span style={{ color: "#F37612" }}>Streamlined</span>,{" "}
                          <span style={{ color: "#F37612" }}>Intelligent</span>{" "}
                           & <span style={{ color: "#F37612" }}>Agile</span>
                        </h2>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>

            <section
              style={{ marginTop: "2.5rem" }}
              className="elementor-section elementor-inner-section elementor-element elementor-element-9e171d0 elementor-section-boxed elementor-section-height-default elementor-section-height-default"
              data-id="9e171d0"
              data-element_type="section"
            >
              <div className="elementor-container elementor-column-gap-default">
                <Swiper
                  slidesPerView={1}
                  spaceBetween={1}
                  loop={true}
                  autoplay={{
                    delay: 1500,
                    disableOnInteraction: false,
                  }}
                  pagination={{
                    clickable: true,
                  }}
                  breakpoints={{
                    640: {
                      slidesPerView: 2,
                      spaceBetween: 20,
                    },
                    1024: {
                      slidesPerView: 3,
                      spaceBetween: 50,
                    },
                  }}
                  modules={[Autoplay, Pagination]}
                  className="mySwiper"
                >
                  {solutions.map((sols, index) => {
                    return (
                      <SwiperSlide key={index}>
                        <div
                          key={index}
                          className="elementor-column elementor-col-100 elementor-inner-column elementor-element elementor-element-6194b98 ot-flex-column-vertical"
                          data-id="6194b98"
                          data-element_type="column"
                        >
                          <div className="elementor-widget-wrap elementor-element-populated">
                            <div
                              className="elementor-element elementor-element-a60d6a2 ot-view-stacked ot-position-top elementor-widget elementor-widget-iiconbox"
                              data-id="a60d6a2"
                              data-element_type="widget"
                              data-widget_type="iiconbox.default"
                            >
                              <div className="elementor-widget-container">
                                <div className="ot-icon-box">
                                  <div className="ot-icon-box__icon">
                                    <img
                                      src={sols.icon}
                                      alt=""
                                      style={{ width: "50px", height: "50px" }}
                                    />
                                  </div>
                                  <div className="ot-icon-box__content">
                                    <h5 className="icon-box-title">
                                      {sols.tiltel}
                                    </h5>
                                    <div className="icon-box-divider">
                                      <span></span>
                                    </div>
                                    <div className="icon-box-des">
                                      {sols.content}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </SwiperSlide>
                    );
                  })}
                  <div
                    sx={{
                      display: "flex",
                      justifyContent: "flex-end",
                      marginRight: "2rem",
                      marginTop: "50px",
                    }}
                  ></div>
                </Swiper>
              </div>
            </section>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Section3;
