import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import "./App.css";

import ScrollToTop from "./Component/ScrollToTop";
import Navbar from "./Navbar/Navbar";
import HomePage from "./Homepage/Homepage";
import Footer from "./Footer/footer";
import ContactUs from "./ContactUs/ContactUs";
import AboutUsPage from "./AboutUsPage/AboutUsPage";
import CaseStudy from "./Resources/CaseStudy";


function App() {
  return (
    <div className="App">
      <ScrollToTop />

      <Router>
        <Navbar />
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/CaseStudy/:caseStudy" element={<CaseStudy/>}/>
          <Route path="/about-us" element={<AboutUsPage/>} />
          <Route path="/contact-us" element={<ContactUs />} />
        </Routes>
        <Footer />
      </Router>
    </div>
  );
}

export default App;
