import React from "react";

import FooterTail from "./FooterTail";
import { Box, Divider } from "@mui/material";
import FooterAbout from "./footerAbout";
import FooterMap from "./FooterMap";
import FooterReach from "./FooterReach";

const Footer = () => {
  return (
    <Box
      backgroundColor={"#fff"}
      sx={{
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
        position: "relative",
        overflow: "hidden",
        zIndex: "1",
        // padding: "5rem",
        // mt:"5rem",
        "@media (max-width:800px)": {
          padding: "2.5rem",
        },
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          paddingTop: "1rem",
          justifyContent: "space-evenly",
          gap: "-2.5rem",
          "@media (max-width :790px)": {
            flexDirection: "column",
            
          },
        }}
      >
        <FooterAbout />
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            // width: "60%",
            "@media (max-width :770px)": {
              flexDirection: "column",
              justifyContent: "space-around",
              width: "auto",
            },
          }}
        >
          <FooterReach/>
          <FooterMap />
          
        </Box>
      </Box>
      <Divider
        orientation="horizontal"
        variant="middle"
        sx={{ borderColor: "white" }}
      />
      <FooterTail />
    </Box>
  );
};

export default Footer;
