import React from "react";
import { Typography, Container } from "@mui/material";

const WelcomeSection = () => {
  return (
    <Container
      maxWidth="lg"
      sx={{  textAlign: { xs: "left", sm: "left" }, py: 5 }}
    >
      <Typography
        variant="h4"
        sx={{
          color: "#0f1a51",
          fontWeight: "bold",
          fontSize: { xs: "1.5rem", sm: "2rem", md: "2rem" },
          fontFamily: "inherit",
        }}
      >
        Welcome to Untangleit
      </Typography>
      <Typography
        variant="h5"
        sx={{
          color: "inherit",
          fontSize: { xs: "1.2rem", sm: "1.25rem" },
          mt: { xs: "1.2rem", sm: "1.5rem" },
          fontFamily: "inherit",
        }}
      >
        We Untangle your business growth with Salesforce. At{" "}
        Untanglet, we're CRM experts
        leveraging Customer 360&deg; to streamline your
        operations & drive efficiency. Focus on what matters most ~ we'll handle
        your Salesforce implementation & beyond.
      </Typography>
    </Container>
  );
};

export default WelcomeSection;
