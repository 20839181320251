import React from "react";
import { Link } from "react-router-dom";
import servNav1 from "../Assets/implementation (1).png";
import servNav2 from "../Assets/support (1).png";
import servNav3 from "../Assets/online-chat.png";
import servNav4 from "../Assets/integration (2).png";
import servNav5 from "../Assets/cloud-computing (1).png";
import servNav6 from "../Assets/online-learning (1).png";
import servNav7 from "../Assets/app-store (3).png";
import servNav8 from "../Assets/integration.png";
import servNav9 from "../Assets/consultant (1).png";

const Services = () => {
  const servNavData = [
    [
      {
        image: servNav1,
        title: "Implementation",
        link: "",
      },

      {
        image: servNav5,
        title: "Integration",
        link: "",
      },

      {
        image: servNav4,
        title: "Data Migration",
        link: "",
      },
    ],

    [
      {
        image: servNav8,
        title: "Development",
        link: "",
      },

      {
        image: servNav2,
        title: "Admin",
        link: "",
      },

      {
        image: servNav3,
        title: "Support",
        link: "",
      },
    ],

    [
      {
        image: servNav9,
        title: "Consulting",
        link: "",
      },

      {
        image: servNav7,
        title: "App Exchange Apps",
        link: "",
      },

      {
        image: servNav6,
        title: "Salesforce Training",
        link: "",
      },
    ],
  ];

  return (
    <>
      <Link
        to="/"
        className="dropdown-toggle"
        role="button"
        data-toggle="dropdown"
        aria-haspopup="true"
        aria-expanded="false"
        data-ps2id-api="true"
      >
        Services
      </Link>

      <ul className="sub-menu sub-mega-menu">
        <li>
          <div className="mega-menu-content">
            <div
              data-elementor-type="wp-post"
              data-elementor-id="8516"
              className="elementor elementor-8516"
            >
              <section
                className="elementor-section elementor-top-section elementor-element elementor-element-791e5814 elementor-section-full_width elementor-section-stretched ot-mega-menu elementor-section-height-default elementor-section-height-default"
                data-id="791e5814"
                data-element_type="section"
                data-settings='{"stretch_section":"section-stretched","background_background":"classNameic"}'
                style={{
                  width: "150rem",
                  height: "40rem",
                  left: "-62rem",
                  backgroundColor: "white",
                }}
              >
                <div
                  className="elementor-container elementor-column-gap-extended"
                  style={{ marginTop: "25px" }}
                >
                  <div
                    className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-63ad8af7 ot-flex-column-vertical"
                    data-id="63ad8af7"
                    data-element_type="column"
                  >
                    <div className="elementor-widget-wrap elementor-element-populated">
                      <section
                        className="elementor-section elementor-inner-section elementor-element elementor-element-b265eea elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                        data-id="b265eea"
                        data-element_type="section"
                        data-settings='{"background_background":"classNameic"}'
                      >
                        <div
                          className="elementor-container elementor-column-gap-extended"
                          style={{
                            display: "flex",
                            justifyContent: "space-evenly",
                          }}
                        >
                          {servNavData.map((servGroup, colIndex) => (
                            <div
                              className="elementor-column elementor-col-20 elementor-inner-column elementor-element elementor-element-737015e ot-flex-column-vertical"
                              data-id="737015e"
                              data-element_type="column"
                              key={colIndex}
                            >
                              <div className="elementor-widget-wrap elementor-element-populated">
                                {servGroup.map((serv, index) => (
                                  <div
                                    className="elementor-element elementor-element-f0870e3 elementor-widget elementor-widget-iimagebox"
                                    data-id="f0870e3"
                                    data-element_type="widget"
                                    data-widget_type="iimagebox.default"
                                    key={index}
                                  >
                                    <div className="elementor-widget-container">
                                      <div className="ot-image-box">
                                        <figure className="ot-image-box__img">
                                          <img
                                            src={serv.image}
                                            title=""
                                            alt=""
                                            loading="lazy"
                                            style={{
                                              width: "5.75rem",
                                              height: "5.75rem",
                                            }}
                                          />
                                        </figure>
                                        <div className="ot-image-box__content">
                                          <h5 className="image-box-title">
                                            <div
                                              className="title-link"
                                              style={{ fontSize: "17px",  fontWeight:"200"}}
                                            >
                                              {serv.title}
                                            </div>
                                          </h5>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                ))}
                              </div>
                            </div>
                          ))}
                        </div>
                      </section>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </li>
      </ul>
    </>
  );
};

export default Services;
