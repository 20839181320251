import React from "react";
import { Typography, Box, Container } from "@mui/material";

const ValuesSection = () => {
  const values = [
    {
      title: "Driven by Innovation",
      description:
        "We leverage cutting-edge technologies to deliver future-proof solutions that adapt to your evolving business needs.",
    },
    {
      title: "Built on Integrity",
      description:
        "We believe in transparency, honesty, and ethical practices in all our engagements, building trust and lasting relationships with our clients.",
    },
    {
      title: "Dedicated to Client Success",
      description:
        "Your success is our priority. We tailor solutions to your unique needs, ensuring your Salesforce implementation delivers maximum value.",
    },
    {
      title: "Committed to Excellence",
      description:
        "We're dedicated to delivering high-quality services and exceeding expectations, providing you with the best possible experience and outcomes.",
    },
  ];

  return (
    <Container
      maxWidth="lg"
      sx={{  textAlign: { xs: "left", sm: "left" }, py: 5 }}
    >
      <Typography
        variant="h4"
        sx={{
          color: "#0f1a51",
          fontWeight: "bold",
          fontSize: { xs: "1.5rem", sm: "2rem", md: "2rem" },
          fontFamily: "inherit",
        }}
        gutterBottom
      >
        Our Values
      </Typography>
      <Box
        component="ul"
        sx={{
          
          textAlign: { xs: "left", sm: "left" },
          listStyleType: "disc",
          paddingLeft: { xs: 2, sm: 4 },
          color: "inherit",
        }}
      >
        {values.map((value, idx) => (
          <Typography
            key={idx}
            component="li"
            sx={{
              marginBottom: "1rem",
              mt: { xs: "1.2rem", sm: "1.5rem" },
              fontSize: { xs: "1.2rem", sm: "1.25rem" },
              fontFamily: "inherit",
            }}
          >
            <strong style={{color:"black"}}>{value.title}</strong> - {value.description}
          </Typography>
        ))}
      </Box>
    </Container>
  );
};

export default ValuesSection;
