import React from "react";
import Partner from "../Assets/Desktop - 1400.png";

const Section7 = () => {
  return (
    <section
      className="elementor-section elementor-top-section elementor-element elementor-element-e750b4a elementor-section-boxed elementor-section-height-default elementor-section-height-default"
      data-id="e750b4a"
      data-element_type="section"
    >
      <div className="elementor-container elementor-column-gap-default">
        <div
          className="elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-ba4ddc0 ot-column-items-center ot-flex-column-vertical"
          data-id="ba4ddc0"
          data-element_type="column"
        >
          <div className="elementor-widget-wrap elementor-element-populated">
            <div
              className="elementor-element elementor-element-2350bd1 elementor-widget elementor-widget-image"
              data-id="2350bd1"
              data-element_type="widget"
              data-widget_type="image.default"
            >
              <div className="elementor-widget-container">
                <img
                  decoding="async"
                  src={Partner}
                  title=""
                  alt=""
                  loading="lazy"
                  data-recalc-dims="1"
                  style={{
                    borderRadius: "50px",
                    height: "18rem",
                    width: "35rem",
                    objectFit: "cover",
                  }}
                />
              </div>
            </div>
          </div>
        </div>
        <div
          className="elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-2cd938e ot-flex-column-vertical"
          data-id="2cd938e"
          data-element_type="column"
        >
          <div className="elementor-widget-wrap elementor-element-populated">
            <div
              className="elementor-element elementor-element-d9a3e0d elementor-widget elementor-widget-heading"
              data-id="d9a3e0d"
              data-element_type="widget"
              data-widget_type="heading.default"
            >
              <div className="elementor-widget-container">
                <h2 className="elementor-heading-title elementor-size-default">
                  Untangleit CRM Solutions:
                  <br /> Salesforce Registered Startup Partner
                </h2>
              </div>
            </div>
            <div
              className="elementor-element elementor-element-8f7dbf6 elementor-widget elementor-widget-text-editor"
              data-id="8f7dbf6"
              data-element_type="widget"
              data-widget_type="text-editor.default"
            >
              <div className="elementor-widget-container">
                <p>
                  At Untangleit, we make the most of our Salesforce expertise,
                  gained through hands-on training to help
                  you get the best out of your CRM. Our certified team is here
                  to tackle your unique challenges with custom solutions that
                  fit your business goals perfectly.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Section7;
