import React from "react";
import { Typography, Container } from "@mui/material";

const ApproachSection = () => (
  <Container
    maxWidth="lg"
    sx={{  textAlign: { xs: "left", sm: "left" }, py: 5 }}
  >
    <Typography
      variant="h4"
      sx={{
        color: "#0f1a51",
        fontWeight: "bold",
        fontSize: { xs: "1.5rem", sm: "2rem", md: "2rem" },
        fontFamily: "inherit",
      }}
      gutterBottom
    >
      Our Approach
    </Typography>
    <Typography
      variant="h5"
      sx={{
        fontSize: { xs: "1.2rem", sm: "1.25rem" },
        mt: { xs: "1.2rem", sm: "1.5rem" },
        fontFamily: "inherit",
        color: "inherit",
      }}
    >
      We believe in a collaborative partnership where we work closely with you
      to understand your unique challenges and deliver Salesforce solutions that
      are both technically sound and strategically aligned to achieve your
      business goals.
    </Typography>
  </Container>
);

export default ApproachSection;
