import { Box, Typography } from "@mui/material";
import React from "react";

const Section2Card = (props) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        width: "250px",
        height: "200px",
        borderRadius: "7.5% 1rem 7.5% 1rem",
        backgroundColor: "white",
        margin: "1rem",
        boxShadow:
          "0 4px 8px 0 rgba(0, 0, 0, 0.1), 0 6px 20px 0 rgba(0, 0, 0, 0.15);",
      }}
    >
      <Box sx={{ width: "100%", marginTop: "-10px", display:"flex", justifyContent:"center", flexDirection:"column" }}>
        <img
          width="60px"
          height="60px"
          alt={props.title}
          src={props.img}
          style={{ objectFit: "cover", alignSelf:"center" }}
        />

        <Typography
          sx={{
            color: "#0F1A51",
            textAlign:"center",
            fontWeight: "700",
            fontSize: "1rem",
            fontFamily:"inherit",
            marginTop: "0.7rem",
          }}
        >
          {props.title}
        </Typography>
        <Typography
          sx={{
            color: "#0F1A51",
            fontWeight: "500",
            fontSize: "0.95rem",
            fontFamily:"inherit",
            marginTop: "0.2rem",
            textAlign: "center",
          }}
        >
          {props.content}
        </Typography>
      </Box>
    </Box>
  );
};

export default Section2Card;
