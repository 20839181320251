import { Stack, Typography } from "@mui/material";
import React from "react";

const FooterTail = () => {
  return (
    <>
      <Stack>
        <Typography
          variant="body1"
          component="div"
          sx={{
            color: "inherit",
            fontFamily: "inherit",
            marginY: "1.5rem",
            textAlign: "center",
            // ml:"10.58rem",
            "@media (max-width:800px)": {
              marginLeft: "0rem",
              textAlign: "center",
            },
          }}
        >
          Copyright 2024 Untangleit, LLC. All Rights Reserved.
        </Typography>
      </Stack>
    </>
  );
};

export default FooterTail;
