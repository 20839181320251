import React from "react";
import CountUp from "react-countup";
import { useInView } from "react-intersection-observer";

const Section5 = () => {
  const { ref, inView } = useInView({
    triggerOnce: true,
    threshold: 0.5,
  });

  const statcolData = [
    [
      {
        number: "50",
        suffix: "+",
        title: "Successful Projects",
      },

      {
        number: "32",
        suffix: "+",
        title: "Salesforce Experts",
      },
    ],

    [
      {
        number: "30",
        suffix: "+",
        title: "Happy Customers",
      },

      {
        number: "30",
        suffix: "+",
        title: "Salesforce Certifications",
      },
    ],
  ];

  return (
    <section
      ref={ref}
      className="elementor-section elementor-top-section elementor-element elementor-element-f2cf1a8 add-margin elementor-section-boxed elementor-section-height-default elementor-section-height-default"
      data-id="f2cf1a8"
      data-element_type="section"
      data-settings='{"background_background":"gradient"}'
    >
      <div className="elementor-background-overlay"></div>
      <div className="elementor-container elementor-column-gap-default">
        <div
          className="elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-4402f26 ot-flex-column-vertical"
          data-id="4402f26"
          data-element_type="column"
        >
          <div className="elementor-widget-wrap elementor-element-populated">
            <div
              className="elementor-element elementor-element-e820547 elementor-widget elementor-widget-heading"
              data-id="e820547"
              data-element_type="widget"
              data-widget_type="heading.default"
              
            >
              <div className="elementor-widget-container">
                <h1 className="elementor-heading-title elementor-size-default">
                  Boost Revenue by <span style={{ color: "#F37612" }}>90%</span>{" "}
                  with <span style={{ color: "#F37612" }}>OUR</span> Expertise
                  Solutions
                </h1>
              </div>
            </div>
            <div
              className="elementor-element elementor-element-d68c172 elementor-widget elementor-widget-text-editor"
              data-id="d68c172"
              data-element_type="widget"
              data-widget_type="text-editor.default"
            >
              <div className="elementor-widget-container">
                <p>Empower success with our specialized solutions and expertise.</p>
              </div>
            </div>
          </div>
        </div>
        <div
          className="elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-5acda1c ot-flex-column-vertical"
          data-id="5acda1c"
          data-element_type="column"
        >
          <div className="elementor-widget-wrap elementor-element-populated">
            <section
              className="elementor-section elementor-inner-section elementor-element elementor-element-6ed80d0 elementor-section-boxed elementor-section-height-default elementor-section-height-default"
              data-id="6ed80d0"
              data-element_type="section"
            >
              <div className="elementor-container elementor-column-gap-default">
                {statcolData.map((statsGroup, colIndex) => (
                  <div
                    className="elementor-column elementor-col-50 elementor-inner-column elementor-element elementor-element-72b276b ot-flex-column-vertical"
                    data-id="72b276b"
                    data-element_type="column"
                    key={colIndex}
                  >
                    <div className="elementor-widget-wrap elementor-element-populated">
                      {statsGroup.map((stat, index) => (
                        <div
                          className="elementor-element elementor-element-a8810cf ot-position-top elementor-widget elementor-widget-icounter"
                          data-id="a8810cf"
                          data-element_type="widget"
                          data-widget_type="icounter.default"
                          key={index}
                        >
                          <div className="elementor-widget-container">
                            <div className="ot-counter">
                              <div className="dflex number-wrapper">
                                {inView && (
                                  <CountUp
                                    className="ot-counter__num"
                                    start={0}
                                    end={parseInt(stat.number)}
                                    duration={3} // Duration in seconds
                                    separator=","
                                  />
                                )}
                                <span
                                  style={{
                                    fontSize: "25px",
                                    height: "50px",
                                    padding: "10px 0px 0px 10px",
                                  }}
                                >
                                  {stat.suffix}
                                </span>
                              </div>
                              <div className="title-desc-wrapper">
                                <h6>{stat.title}</h6>
                              </div>
                            </div>
                          </div>
                          <div
                            className="elementor-element elementor-element-1a80c21 elementor-widget elementor-widget-spacer"
                            data-id="1a80c21"
                            data-element_type="widget"
                            data-widget_type="spacer.default"
                          >
                            <div className="elementor-widget-container">
                              <div className="elementor-spacer">
                                <div className="elementor-spacer-inner"></div>
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                ))}
              </div>
            </section>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Section5;
