import React from "react";
import { Link } from "react-router-dom";

const Home = () => {
  return (
    <>
      <Link
        to="/"
        className="dropdown-toggle"
        role="button"
        data-toggle="dropdown"
        aria-haspopup="true"
        aria-expanded="false"
        data-ps2id-api="true"
        
      >
        Home
      </Link>
      
    </>
  );
};

export default Home;
