import React from "react";
import { Typography, Container } from "@mui/material";

const MissionSection = () => {
  return (
    <Container
      maxWidth="lg"
      sx={{  textAlign: { xs: "left", sm: "left" }, py: 5 }}
    >
      <Typography
        variant="h4"
        sx={{
          color: "#0f1a51",
          fontWeight: "bold",
          fontSize: { xs: "1.5rem", sm: "2rem", md: "2rem" },
          fontFamily: "inherit",
        }}
        gutterBottom
      >
        Our Mission
      </Typography>
      <Typography
        variant="h5"
        sx={{
          color: "inherit",
          fontSize: { xs: "1.2rem", sm: "1.25rem" },
          mt: { xs: "1.2rem", sm: "1.5rem" },
          fontFamily: "inherit",
        }}
      >
        To empower businesses with Salesforce solutions that streamline
        operations and drive growth. We’re dedicated to providing innovative
        solutions, expert support, and exceptional value.
      </Typography>
    </Container>
  );
};

export default MissionSection;
