import React from "react";
import NavbarDesktop from "./NavbarDesktop";
import NavbarPhone from "./NavbarPhone";

const Navbar = () => {
  return (
    <nav id="site-header" className="site-header header-transparent">
     
      <NavbarDesktop/>
      <NavbarPhone/>
      
    </nav>
  );
};

export default Navbar;
