const caseStudies = [
  {
    caseStudy: "Sales",
    title: "Salesforce Optimization for a Growing Fintech Solutions Provider",
    client:
      "A leading provider of software solutions for the financial industry, serving a diverse range of clients, from established banks to innovative fintech startups.",
    challenge: {
      description:
        "Rapid growth was straining their sales team. They needed a better way to manage their increasing volume of leads and close deals faster, all within Salesforce, their CRM platform.",
      details: [],
    },
    solution: {
      description:
        "We supercharged their Salesforce with a powerful dialer and automation system. This included:",
      title: [
        "Streamlined Calling",
        "Automated Emails",
        "Smart Task Management",
        "Regional Performance Dashboards",
      ],
      details: [
        "Integrated Salesforce Dialer with Amazon Connect to make calling more efficient and organized.",
        "Created email sequences that automatically nurture leads with timely and relevant information.",
        "Automated tasks to ensure critical follow-ups were never missed.",
        "Built custom dashboards to give managers clear visibility into sales performance across different regions.",
      ],
    },
    results: {
      title: [
        "Happier Sales Team",
        "More Engaged Leads",
        "Data-Driven Decisions",
        "Faster Sales",
      ],

      details: [
        "Automation freed up their time to focus on what matters most: building relationships and closing deals.",
        "Timely communication kept leads interested and improved conversion rates.",
        "Regional dashboards empowered managers with the insights they needed to make informed decisions.",
        "Optimized processes and improved communication helped them close deals faster.",
      ],
    },

    technologyUsed: [
      "Salesforce Sales Cloud",
      "Salesforce Dialer",
      "Amazon Connect CTI",
      "Cognito Forms",
      "Salesforce Apex",
    ],
  },
  {
    caseStudy: "Marketing",
    title: "Connecting Healthcare: A Marketing Cloud Success Story",
    client:
      "A pioneering healthcare company that bridges the gap between pharmacies, consumers, and charities by offering medication discount coupons through their online portal.",
    challenge: {
      description:
        "They wanted to deepen relationships with their users and boost engagement. To do this, they needed a powerful marketing platform that could deliver personalized messages across multiple channels.",
      details: [],
    },
    solution: {
      description:
        "We built them a complete marketing solution from the ground up using Salesforce Marketing Cloud. This included:",
      title: [
        "Personalized Messaging",
        "Automated Journeys",
        "Multi-Channel Reach",
        "Optimized Deliverability",
        "Data-Driven Insights",
      ],
      details: [
        "Crafted emails tailored to individual needs using AMPscript and Server-Side JavaScript.",
        "Designed targeted customer journeys to deliver the right message at the right time to consumers, pharmacists, and charities.",
        "Integrated email, mobile SMS, and their web portal for a seamless and connected experience.",
        "Ensured emails reached the inbox with a carefully executed IP warmup process.",
        "Customized their data model to gain a deeper understanding of their audience and optimize segmentation.",
      ],
    },
    results: {
      title:[],
      details:["A significant increase in customer engagement and retention. Personalized and timely communications resonated with their audience, fostering stronger connections and building loyalty."],
    },
      
    
    technologyUsed: [
      "Salesforce Marketing Cloud",
      "Journey Builder",
      "Contact Builder",
      "Email Studio",
      "Mobile Studio",
      "Automation Studio",
      "AMPscript",
      "Server-Side JavaScript (SSJS)",
      "API Integration",
    ],
  },
  {
    caseStudy: "Health",
    title: "Salesforce Implementation for a UK-Based Healthcare Organization",
    client:
      "A UK-based healthcare organization that operates multiple facilities and diagnostic centers, providing services ranging from clinics to quaternary care.",
    challenge: {
      description:
        "The organization faced several operational challenges, including:",
      details: [
        "Low patient awareness of health conditions.",
        "Limited access to real-time data.",
        "Inefficient appointment management.",
        "High call volumes that strained their support center, negatively impacting patient satisfaction.",
      ],
    },
    solution: {
      description:
        "We implemented Salesforce to enhance patient care and streamline operations. Key features included:",
      title: [
        "Health Cloud Integration",
        "Automated Scheduling",
        "Patient Engagement Portal",
        "Self-Service Knowledge Base",
        "Personalized Care Plans",
      ],
      details: [
        "Utilized Salesforce Health Cloud for managing patient profiles and integrating wearable data for real-time health monitoring.",
        "Developed an automated scheduling system to improve appointment management and reduce administrative burdens on staff.",
        "Created a portal that allows patients to engage with their healthcare providers easily, access resources, and receive reminders.",
        "Implemented a knowledge base that enables patients to find answers to common questions independently, improving efficiency.",
        "Introduced personalized care plans with measurable goals, allowing doctors to access comprehensive health timelines for their patients.",
      ],
    },
    results: {
      title: [
        "Enhanced Patient Awareness",
        "Improved Real-Time Data Access",
        "Streamlined Appointment Management",
        "Increased Patient Engagement",
      ],
      details: [
        "Patients became more informed about their health conditions through improved communication and resources.",
        "Integration of wearable devices provided healthcare providers with a comprehensive view of patient health, enabling timely interventions.",
        "Automation reduced scheduling errors and improved the overall efficiency of appointment handling.",
        "The patient engagement portal and personalized care plans fostered stronger relationships between patients and healthcare providers.",
      ],
    },
    technologyUsed: [
      "Salesforce Health Cloud",
      "Integration of wearable devices",
      "Automated scheduling tools",
      "Self-service knowledge base solutions",
      "Zoom for virtual check-ins",
    ],
  },
];

export default caseStudies;
