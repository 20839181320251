import React from "react";
import { Typography, Container } from "@mui/material";

const WhoWeAreSection = () => (
  <Container
    maxWidth="lg"
    sx={{ textAlign: { xs: "left", sm: "left" }, py: 5 }}
  >
    <Typography
      variant="h4"
      sx={{
        color: "#0f1a51",
        fontWeight: "bold",
        fontSize: { xs: "1.5rem", sm: "2rem", md: "2rem" },
        fontFamily: "inherit",
      }}
      gutterBottom
    >
      Who We Are
    </Typography>
    <Typography
      variant="h5"
      sx={{
        color: "inherit",
        fontSize: { xs: "1.2rem", sm: "1.25rem" },
        mt: { xs: "1.2rem", sm: "1.5rem" },
        fontFamily: "inherit",
      }}
    >
      At Untangleit, we’re a team of dedicated Salesforce experts with a
      passion for technology and a deep understanding of the Salesforce
      ecosystem. Our diverse team of cloud architects, engineers, and
      consultants brings together years of experience and a wealth of knowledge
      to tackle the most complex challenges.
    </Typography>
  </Container>
);

export default WhoWeAreSection;
