import React, { useLayoutEffect, useState } from "react";
import emailjs from "emailjs-com";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "../ContactUs/contactUs.css";

const ContactUs = () => {
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    service: "",
    email: "",
    message: "",
  });

  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    emailjs
      .send(
        "service_w2wgdi7", 
        "template_uoo1xii", 
        formData,
        "UFuIuRe9Wv5yssJ6M"
      )
      .then(
        () => {
          toast.success("Form submitted successfully!"); 
          setFormData({
            firstName: "",
            lastName: "",
            service: "",
            email: "",
            message: "",
          });
        },
        (error) => {
          console.error("Failed to send form data:", error);
          toast.error("There was an error submitting the form.");
        }
      );
  };

  return (
    <section
      className="contact-area pt-120 pb-120"
      style={{
        backgroundColor: "transparent",
        backgroundImage: "linear-gradient(180deg, #DFE4FF 50%, #ffffff 120%)",
      }}
    >
      <ToastContainer />
      <div className="container" style={{ marginTop: "70px" }}>
        <div className="row justify-content-center">
          <div className="col-xl-8 col-lg-10">
            <div className="section-title text-center mb-60">
              <span>Get in touch with us</span>
            </div>
            <div className="contact-form text-center">
              <form onSubmit={handleSubmit}>
                <div className="row">
                  <div className="col-md-6">
                    <input
                      type="text"
                      name="firstName"
                      placeholder="First Name*"
                      value={formData.firstName}
                      onChange={handleChange}
                      required
                    />
                  </div>
                  <div className="col-md-6">
                    <input
                      type="text"
                      name="lastName"
                      placeholder="Last Name"
                      value={formData.lastName}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="col-md-6">
                    <input
                      type="email"
                      name="email"
                      placeholder="Email Id*"
                      value={formData.email}
                      onChange={handleChange}
                      required
                    />
                  </div>
                  <div className="col-md-6">
                    <select
                      id="services"
                      name="service"
                      value={formData.service}
                      onChange={handleChange}
                      required
                      style={{
                        width: "100%",
                        maxWidth: "100%",
                        border: "1px solid #ebebeb",
                        borderRadius: "5px",
                        padding: "12px 15px",
                        fontFamily: "inherit",
                        fontSize: "14px",
                        color: "#786d99",
                        outline: "none",
                        boxSizing: "border-box",
                        margin: "0",
                        height: "auto",
                        minHeight: "59px",
                        maxHeight: "10vh",
                        overflow: "hidden",
                        "@media (maxWidth:767px)": {
                          marginBottom: "50px",
                        },
                      }}
                    >
                      <option value="" disabled>
                        Services*
                      </option>
                      <option value="Implementation">Implementation</option>
                      <option value="Development">Development</option>
                      <option value="Consulting">Consulting</option>
                      <option value="Integration">Integration</option>
                      <option value="Admin">Admin</option>
                      <option value="App Exchange Apps">
                        App Exchange Apps
                      </option>
                      <option value="Data Migration">Data Migration</option>
                      <option value="Support">Support</option>
                      <option value="Training">Training</option>
                      <option value="Other value">Any Other</option>
                    </select>
                  </div>
                  
                </div>
                <textarea
                  name="message"
                  placeholder="Your Message"
                  value={formData.message}
                  onChange={handleChange}
                  required
                ></textarea>
                <button type="submit" className="btn">
                  Submit Request
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ContactUs;
