import { Typography } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";

const LogIn = ({ isScrolled, inNavbar, inFooter }) => {
  const handleMouseEnter = (e) => {
    if (!inFooter) {
      e.target.style.transform = "scale(1.05)";
    }
  };

  const handleMouseLeave = (e) => {
    if (!inFooter) {
      e.target.style.transform = "scale(1)";
    }
  };

  return (
    <div
      className="elementor-column elementor-col-25 elementor-top-column elementor-element elementor-element-2e822260 ot-column-items-center ot-flex-column-horizontal"
      data-id="2e822260"
      data-element_type="column"
    >
      <div className="elementor-widget-wrap elementor-element-populated">
        <div
          className="elementor-element elementor-element-6697ddc7 elementor-align-right elementor-widget__width-initial elementor-widget elementor-widget-ibutton"
          data-id="6697ddc7"
          data-element_type="widget"
          data-widget_type="ibutton.default"
        >
          <div className="elementor-widget-container">
            <div className="ot-button">
              <Link
                to="/contact-us"
                className="octf-btn octf-btn-second"
                style={{
                  boxShadow:
                    inNavbar && !isScrolled
                      ? ""
                      : "0 8px 16px rgba(0, 0, 0, 0.2), 0 12px 30px rgba(0, 0, 0, 0.25)",
                  padding: "12px 24px",
                  fontWeight: "bold",
                  fontSize: "16px",
                  textShadow: !inNavbar
                    ? "1px 1px 5px rgba(0, 0, 0, 0.2)"
                    : "none",
                  transition:
                    "transform 0.3s ease, box-shadow 0.3s ease, color 0.3s ease",
                }}
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
              >
                {inNavbar ? (
                  "Reach Out Now!"
                ) : inFooter ? (
                  <Typography sx={{ fontFamily: "inherit", color: "blue" }}>
                    Get{" "}
                    <span style={{ color: "#F37612", fontWeight: "bold" }}>
                      In
                    </span>{" "}
                    Touch
                  </Typography>
                ) : (
                  "Let's Collaborate!"
                )}
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LogIn;
