import React, { useState, useEffect } from "react";

import Services from "./Services";
import Home from "./Home";
import Expertise from "./Expertise";
import Resources from "./Resources";
import AboutUs from "./AboutUs";
// import Careers from "./Careers";
import LogIn from "./LogIn";
import Logo from "./Logo";

const NavbarDesktop = () => {
  const [isScrolled, setIsScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 50) {
        // Adjust the scroll position value as needed
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <div className="header-desktop">
      <div
        data-elementor-type="wp-post"
        data-elementor-id="4548"
        className="elementor elementor-4548"
        style={{
          backgroundColor: isScrolled
            ? "white"
            : "transparent",
          position: isScrolled ? "fixed" : "relative",
          width: "100%",
          top: 0,
          zIndex: 10, 
          transition: "background-color 0.0125s ease, position 0.0125s ease",
          
          border: isScrolled ? "1px solid rgba(255, 255, 255, 0.18)" : "none", 
         
          height: isScrolled ? "7.5rem" : "inherit",
        }}
      >
        <section
          className="elementor-section elementor-top-section elementor-element elementor-element-5fe1eb60 elementor-section-full_width add-margin elementor-section-height-default elementor-section-height-default"
          data-id="5fe1eb60"
          data-element_type="section"
          
        >
          <div
            className="elementor-container elementor-column-gap-no"
             
          >
            <Logo />
            <div
              className="elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-7d018ea8 ot-flex-column-vertical"
              data-id="7d018ea8"
              data-element_type="column"
              
            >
              <div className="elementor-widget-wrap elementor-element-populated" >
                <div
                  className="elementor-element elementor-element-35bff668 elementor-widget elementor-widget-imenu"
                  data-id="35bff668"
                  data-element_type="widget"
                  data-widget_type="imenu.default"
                  
                >
                  <div className="elementor-widget-container" >
                    <nav id="site-navigation" className="main-navigation" >
                      <ul id="primary-menu" className="menu">
                        <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-5212 dropdown hasmenu">
                          <Home />
                        </li>
                        <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-home current-menu-ancestor current-menu-parent current_page_parent current_page_ancestor menu-item-has-children menu-item-8515 active dropdown is-mega-menu dropdown is-mega-menu">
                          <Services />
                        </li>
                        <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-home current-menu-ancestor current-menu-parent current_page_parent current_page_ancestor menu-item-has-children menu-item-8515 active dropdown is-mega-menu dropdown is-mega-menu">
                          <Expertise />
                        </li>
                        <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-has-children menu-item-5226 dropdown hasmenu">
                          <Resources />
                        </li>
                        <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-5209">
                          <AboutUs />
                        </li>
                        {/* <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-1569">
                          <Careers />
                        </li> */}
                      </ul>
                    </nav>
                  </div>
                </div>
              </div>
            </div>
            <LogIn isScrolled={true} inNavbar = {true}/>
          </div>
        </section>
      </div>
    </div>
  );
};

export default NavbarDesktop;
