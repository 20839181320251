import React from "react";
import cloud1 from "../Assets/provider.png";
import cloud2 from "../Assets/cloud-computing.png";
import pardot from "../Assets/money_16387004.png";
import cloud4 from "../Assets/cloud_6923091.png";
import cloud5 from "../Assets/comCloud.png";
import cloud6 from "../Assets/computing-cloud.png";
import cloud7 from "../Assets/cloud.png";
import cloud8 from "../Assets/government.png";

const Section6 = () => {
  const servData = [
    [
      {
        image: cloud1,
        title: "Service Cloud",
        content:
          "Transform your customer support with Service Cloud. We'll help you manage cases efficiently, empower customers with self-service, and deliver seamless support across every channel.",
      },
      {
        image: cloud2,
        title: "Sales Cloud",
        content:
          "Give your sales team the tools they need to succeed. We'll help you manage leads, track opportunities, and close more deals with Sales Cloud's powerful features.",
      },
    ],
    [
      {
        image: pardot,
        title: "Pardot",
        content:
          "Engage your audience with targeted messages across all channels. We'll help you harness the power of Pardot to create personalized marketing campaigns that deliver results..",
      },
      {
        image: cloud4,
        title: "Experience Cloud",
        content:
          "Connect with your customers, partners, and employees in a whole new way. We'll help you create vibrant online communities with Experience Cloud, fostering collaboration and knowledge sharing.",
      },
    ],
    [
      {
        image: cloud5,
        title: "B2B Commerce Cloud",
        content:
          "Drive sales and build customer loyalty with Commerce Cloud. We'll help you create engaging and personalized shopping experiences across your website, mobile app, and social media channels.",
      },
      {
        image: cloud6,
        title: "Health Cloud",
        content:
          "Deliver coordinated and personalized care to your patients. We'll help you implement Health Cloud to manage patient relationships, streamline processes, and improve health outcomes.",
      },
    ],
    [
      {
        image: cloud7,
        title: "Financial Services Cloud",
        content:
          "Deliver personalized advice and exceptional service to your clients. We'll help you implement Financial Services Cloud to manage relationships, streamline processes, and drive growth.",
      },
      {
        image: cloud8,
        title: "Government Cloud",
        content:
          "Meet your agency's unique needs with Government Cloud. We'll help you implement secure and compliant solutions to modernize operations and improve citizen services..",
      },
    ],
  ];

  return (
    <section
      className="elementor-section elementor-top-section elementor-element elementor-element-427149c add-margin elementor-section-boxed elementor-section-height-default elementor-section-height-default"
      data-id="427149c"
      data-element_type="section"
      data-settings='{"background_background":"classNameic"}'
    >
      <div className="elementor-container elementor-column-gap-default">
        <div
          className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-c6c4b78 ot-flex-column-vertical"
          data-id="c6c4b78"
          data-element_type="column"
        >
          <div className="elementor-widget-wrap elementor-element-populated">
            <div
              className="elementor-element elementor-element-c17d185 elementor-widget elementor-widget-spacer"
              data-id="c17d185"
              data-element_type="widget"
              data-widget_type="spacer.default"
            >
              <div className="elementor-widget-container">
                <div className="elementor-spacer">
                  <div className="elementor-spacer-inner"></div>
                </div>
              </div>
            </div>

            <section
              className="elementor-section elementor-inner-section elementor-element elementor-element-3b67964 elementor-section-boxed elementor-section-height-default elementor-section-height-default"
              data-id="3b67964"
              data-element_type="section"
            >
              <div className="elementor-container elementor-column-gap-default">
                <div
                  className="elementor-column elementor-col-100 elementor-inner-column elementor-element elementor-element-158c27d ot-flex-column-vertical"
                  data-id="158c27d"
                  data-element_type="column"
                >
                  <div className="elementor-widget-wrap elementor-element-populated">
                    <div
                      className="elementor-element elementor-element-b0d750c elementor-widget elementor-widget-heading"
                      data-id="b0d750c"
                      data-element_type="widget"
                      data-widget_type="heading.default"
                    >
                      <div className="elementor-widget-container">
                        <h2
                          className="elementor-heading-title elementor-size-default"
                          style={{ fontSize: "30px" }}
                        >
                          WHAT <span style={{ color: "#F37612" }}>WE</span> DO
                        </h2>
                      </div>
                    </div>
                    <div
                      className="elementor-element elementor-element-e8bb90c elementor-widget elementor-widget-text-editor"
                      data-id="e8bb90c"
                      data-element_type="widget"
                      data-widget_type="text-editor.default"
                    >
                      <div className="elementor-widget-container">
                        <h5 style={{ fontSize: "25px", textAlign: "center" }}>
                          Empower Businesses with Streamline Processes <br /> to
                          Drive Success through{" "}
                          <span style={{ color: "#F37612" }}>Impactful</span> & {" "}
                          <span style={{ color: "#F37612" }}>Tailored</span> {" "}
                           Solutions
                        </h5>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>

            {servData.map((servGroup, rowIndex) => (
              <section
                className="elementor-section elementor-inner-section elementor-element elementor-element-0f654d7 elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                data-id="0f654d7"
                data-element_type="section"
                key={rowIndex}
              >
                <div className="elementor-container elementor-column-gap-default">
                  {servGroup.map((serv, index) => (
                    <div
                      className="elementor-column elementor-col-50 elementor-inner-column elementor-element elementor-element-5d89801 ot-flex-column-vertical"
                      data-id="5d89801"
                      data-element_type="column"
                      key={`${rowIndex}-${index}`} // Add unique key for each item
                    >
                      <div className="elementor-widget-wrap elementor-element-populated">
                        <div
                          className="elementor-element elementor-element-841b4aa ot-view-stacked ot-position-left ot-vertical-align-top elementor-widget elementor-widget-iiconbox"
                          data-id="841b4aa"
                          data-element_type="widget"
                          data-widget_type="iiconbox.default"
                        >
                          <div className="elementor-widget-container">
                            <div className="ot-icon-box">
                              <div className="ot-icon-box__icon">
                                <img
                                  src={serv.image}
                                  alt={serv.title}
                                  style={{ width: "50px", height: "50px" }}
                                />
                              </div>
                              <div className="ot-icon-box__content">
                                <h5 className="icon-box-title">{serv.title}</h5>
                                <div className="icon-box-divider">
                                  <span></span>
                                </div>
                                <div className="icon-box-des">
                                  {serv.content}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </section>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

export default Section6;
