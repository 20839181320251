import React from "react";
import untangleLogo from '../Assets/source File-1 (1).png';
import { Link } from "react-router-dom";

const Logo = () => {
  return (
    <div
      className="elementor-column elementor-col-25 elementor-top-column elementor-element elementor-element-69365872 ot-column-items-center ot-flex-column-vertical"
      data-id="69365872"
      data-element_type="column"
    >
      <div className="elementor-widget-wrap elementor-element-populated">
        <div
          className="elementor-element elementor-element-29fca55 elementor-widget elementor-widget-ilogo"
          data-id="29fca55"
          data-element_type="widget"
          data-widget_type="ilogo.default"
        >
          <div className="elementor-widget-container">
            <div className="the-logo">
              <Link to="/">
                <img
                  src={untangleLogo}
                  alt="Untangleit"
                  style={{
                   
                    filter: "drop-shadow(-10px #fff)",
             
                  }}
                  
                />
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Logo;
