import React, { useLayoutEffect } from "react";
import Herosection from "./Herosection";
import Section2 from "./section2";
import Section3 from "./section3";

import Section5 from "./section5";
import Section6 from "./section6";
import Section7 from "./section7";
import Section8 from "./section8";

const Homepage = () => {
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  });
  return (
    <div id="content" className="site-content">
      <div
        data-elementor-type="wp-page"
        data-elementor-id="4516"
        className="elementor elementor-4516"
      >
        {/* landing page */}
        <Herosection />

        {/* about us */}
        <Section2 />

        {/* services */}
        <Section3 />

        {/* expertise */}
        <Section6 />

        {/* count stats */}
        <Section5 />

        {/* Salesforce partner page */}
        <Section7 />

        {/* Testimonial */}
        <Section8 />
      </div>
    </div>
  );
};

export default Homepage;
