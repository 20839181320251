import React from "react";


import { Box, IconButton, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import gmail from "../Assets/gmail.png";
import linkedin from "../Assets/linkedin.png";
import LogIn from "../Navbar/LogIn";

const FooterReach = () => {
  

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-evenly",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          gap: "7.5rem",
          my: "1.5rem",
          // ml: "-150px",

          "@media (max-width :1399px)": {
            flexDirection: "column",

            position: "relative",
            width: "280px",
            
            gap: 0,
          },
        }}
      >
        <Link
          to="mailto:info@untangleit.in"
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            gap: "10px",
          }}
        >
          <IconButton sx={{ height: "2.5rem", mt: "0.75rem" }} aria-label="Mail">
            <img src={gmail} alt="" style={{ width: "25px" }} />
          </IconButton>
          <Typography
            sx={{
              fontFamily: "inherit",
              color: "#454c6c",
              my: "1.25rem",
              transition: "color 0.3s ease", // Smooth transition for color change
              "&:hover": {
                color: "purple", // Change color to purple on hover
              },
            }}
          >
            {" "}
            info@untangleit.in
          </Typography>
        </Link>
        <Link
          to="https://www.linkedin.com/company/untangleit/"
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            gap: "10px",
          }}
        >
          <IconButton sx={{ height: "2.5rem", mt: "0.75rem" }} aria-label="linkedIn">
            <img src={linkedin} alt="" style={{ width: "25px" }} />
          </IconButton>
          <Typography
            sx={{
              fontFamily: "inherit",
              color: "#454c6c",
              my: "1.25rem",
              transition: "color 0.3s ease", // Smooth transition for color change
              "&:hover": {
                color: "purple", // Change color to purple on hover
              },
            }}
          >
            {" "}
            Untangleit
          </Typography>
        </Link>
        {/* <Box sx={{ bgcolor: "red" }}>
          <LogIn />
        </Box> */}

        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            gap: "10px",
            justifyContent: "space-evenly",
            alightItems: "center",
            fontWeight: "bold",
            color: "white",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              // gap: "10px",
              // ml:"12.5rem",
              
              "@media (max-width :1399px && max-width:768px)": {
                flexDirection: "column",
                justifyContent: "space-evenly",
                position: "relative",
                width: "280px",
                left: "-3rem",
                mt: "0.8rem",
              },
            }}
          >
            

            <LogIn inFooter={true}/>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default FooterReach;
